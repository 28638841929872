<template>
  <div class="relative w-full h-full">
    <iframe
      v-if="!showPreview"
      class="pdfContainer"
      :src="'/pdf/web/viewer.html?file=' + source">
    </iframe>
    <div
      class="preview"
      v-if="showPreview && preview"
      @click="openInNewTab">
      <img :src="preview">
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'layout',
      'category',
      'showPreview',
      'isDigitalSignage',
    ],
    computed: {
      source() {
        return this.category?.media?.links?.find(l => l.rel === 'download')?.href
      },
      preview() {
        return this.category?.media?.links?.find(l => l.rel === 'preview')?.href
      }
    },
    async created() {
      if (!this.source) {
        if (this.$route.params.slug) {
          return this.go(`/${this.$route.params.slug}/auth/login`)
        }
        return this.go('/auth/login')
      }
    },
    methods: {
      openInNewTab() {
        let element = document.createElement('a')
        element.href = '/pdf/web/viewer.html?file=' + this.source
        element.target = '_blank'
        element.click()
        element.remove()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pdfContainer {
    @apply
      w-full
      h-full;
  }

  .preview {
    @apply
      flex
      top-0
      left-0
      w-full
      h-full
      absolute
      max-w-full
      max-h-full
      justify-center;

    img {
      @apply
        max-w-full
        max-h-full;
    }
  }
</style>